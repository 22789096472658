import React from "react"
import MainLayout from "../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../components/ContactHeading"
import ListWithDescription from "../components/ListWithDescription"

export const pageQuery = graphql`
  query {
    autoBackgroundImage: file(relativePath: { eq: "auto-background.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInCarImage: file(relativePath: { eq: "leo-in-car.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithRekeyingLockImage: file(
      relativePath: { eq: "locksmith-rekeying-lock.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithCarInNeighborhoodImage: file(
      relativePath: { eq: "locksmith-car-in-neighborhood.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const servicesData = [
  {
    heading: "24/7 Emergency Lockout",
    description: `If you find yourself locked out of your vehicle, there is no need to worry. Our automotive locksmiths are always available for your convenience, 24 hours a day, 7 days a week. We respond to your call as quickly as possible and head to your location to assist you and get you back on the road. For tips on unlocking your car, click <a target="_blank" href="https://swiftlocksmith.blogspot.com/2019/11/car-door-unlocking-tips.html" >here</a> to read more.`,
  },
  {
    heading: "Auto Re-key",
    description:
      "We come to you for all of your auto rekey needs. We carry all the proper tools and our van is set up with the right equipment necessary to successfully rekey your car keys on-site and all in one day. The next time you have any auto rekey needs, give Swift Locksmith a call and we will be happy to serve you!",
  },
  {
    heading: "Car Key Replacements",
    description:
      "If you have a broken or damaged car lock, we are here to help. We carry a wide variety of locks and can special order one in certain cases. Our technicians are experts at car lock replacements and can determine whether you will need to repair or replace your car lock at the time of the consultation.",
  },
  {
    heading: "Car Lock Replacements",
    description:
      "If you have a broken or damaged car lock, we are here to help. We carry a wide variety of locks and can special order one in certain cases. Our technicians are experts at car lock replacements and can determine whether you will need to repair or replace your car lock at the time of the consultation.",
  },
  {
    heading: "Auto Door Unlocks",
    description:
      "Swift Locksmith technicians are highly trained in a wide range of automotive locksmithing and specialize in high security and transponder keys, recode locks, keys by code and more. We use the latest technology and have all the proper equipment to unlock any auto locks in a timely manner and at competitive pricing.",
  },
  {
    heading: "Programming Car Key",
    description: `Our auto locksmiths are experienced in <a href="https://swift-locksmith.tumblr.com/post/189479238411/the-benefits-of-lock-rekeying" target="_blank">car key programming</a> and we provide efficient, affordable and reliable services so that your key is programmed to function correctly. We come to you with the proper equipment and use the latest technology to guarantee that your car key is programmed correctly the first time around.`,
  },
]

function CommercialPage({ data }) {
  return (
    <MainLayout
      title="Automotive Locksmith | 916-995-0135 | Cheap Lockout Service"
      description="Locked out of your car with a lost car key or a broken ignition key? Lost your car keys? Swift Locksmith has you covered. Affordable & fast response time."
      offset
    >
      <ContactHeading
        background={data.autoBackgroundImage.childImageSharp.fluid}
      >
        <h1>Automotive Locksmith Services</h1>
      </ContactHeading>
      <Container as="section">
        <h2 className="h2 text-center">Auto Locksmith Solutions</h2>
        <p className="indent mb-0">
          Swift Locksmiths offer many services from emergency to non-emergency
          problems. When it comes to problems with your car and getting into it,
          Swift locksmiths are very resourceful in helping you. We make sure
          that our locksmith’s are up to date with the latest certifications and
          newest technology.Our locksmith services are available 24 hours, 7
          days a week in Sacramento and the surrounding areas. Car ignition
          lock-outs, lock-ins, and different types of car ignition problems are
          just some of the automotive services we offer. With the latest
          equipment, our trained locksmiths are able to fix problems such as
          repairing a transponder key in a matter of minutes. Whether you need a
          new set of keys, new transponder keys, any type of ignition repair or
          find yourself in any type of car emergency, call Swift Locksmiths for
          assistance.
        </p>
      </Container>
      <section>
        <ListWithDescription items={servicesData} heading="Auto Services" />
      </section>
      <Container as="section">
        <Row>
          <Col className="col-sm-pad">
            <div id="image-grid-auto">
              <Img
                fluid={data.leoInCarImage.childImageSharp.fluid}
                alt="leo-in-car-reprogramming-key"
                style={{ maxWidth: "100%", gridArea: "a" }}
              />
              <Img
                fluid={
                  data.locksmithCarInNeighborhoodImage.childImageSharp.fluid
                }
                alt="locksmith-car-in-neighborhood"
                className="d-none d-md-block"
                style={{ maxWidth: "100%", gridArea: "b" }}
              />
              <Img
                fluid={data.locksmithRekeyingLockImage.childImageSharp.fluid}
                alt="locksmith-rekeying-lock"
                style={{ maxWidth: "100%", gridArea: "c" }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container as="section" className="mb-5">
        <h2>Service For Car Lock-Outs</h2>
        <p className="indent mb-0">
          Car lock outs are among the most common locksmith related incident.
          Many of our clients tend to misplace, lose their set of keys or get
          their keys locked inside of their vehicle. If you ever find yourself
          locked out of your car, do not feel bad. Many people at one point or
          another will experience this type of problem and the best thing to do
          is to call us right away and get the help you need. Swift locksmiths
          are professionally trained and very knowledgeable in lockouts and are
          always nearby, ready to help at any given notice. You know that you
          are in good hands when we come and get you out of a lockout.
        </p>
      </Container>
    </MainLayout>
  )
}

export default CommercialPage
